import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={['TensorFlow Art', 'Custom Art', 'Machine Learning', 'Artificial Intelligence']}
        title="Try It Now"
      />
      <section>
        <h1 className="inline-block mb-2 text-4xl font-bold text-white">Try It!</h1>
        <h2 className="text-white text-xl text-left mb-2">
          Want to be a part of our beta and get a free custom creation?
        </h2>
        <h2 className="text-white text-xl text-left">
          Enter your information below and we will be in touch!
        </h2>
        <form
          name="contact"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          className="mx-auto mt-6"
        >
          <div className="grid grid-cols-1 md:grid-cols-4 flex items-end">
            <div className="col-span-1 md:col-span-2">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />

              <input
                className="w-full form-input py-2 px-4"
                id="inputEmail"
                placeholder="Email"
                type="email"
                name="email"
              />
            </div>
            <div className="col-span-1 md:col-span-2 md:pl-3 mt-4 md:mt-0">
              <input
                className="w-full form-input py-2 px-4"
                id="inputName"
                placeholder="Name"
                name="name"
              />
            </div>
            <div className="col-span-1 md:col-span-4 mt-4">
              <textarea
                className="w-full form-input py-2 px-4 placeholder-gray-500"
                id="comment"
                name="comment"
                rows="4"
                placeholder="Have a question or comment? Leave it here!"
              />
            </div>

            <div className="col-span-1 lg:col-span-4 pt-5 text-left">
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-green-600 rounded hover:bg-green-800"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </Layout>
  );
}
export default ContactPage;
